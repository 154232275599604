import React, { useState, useEffect } from 'react';
import '../styles/Navbar.css';

const menuItems = [
  { name: 'Home', ref: 'homeRef' },
  { name: 'About', ref: 'aboutRef' },
  { name: 'Resume', ref: 'resumeRef' },
  { name: 'My Blog', ref: 'blogRef' },
  { name: 'Contact', ref: 'contactRef' },
];

function Navbar({ scrollToSection, refs }) {
  const [activeSection, setActiveSection] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleScroll = () => {
    const sections = Object.keys(refs).map(ref => refs[ref]);
    let scrollPosition = window.scrollY;

    sections.forEach(section => {
      const sectionTop = section.offsetTop;
      const sectionHeight = section.clientHeight;

      if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
        setActiveSection(section.getAttribute('id'));
      }
    });
  };

  const handleMenuClick = (ref) => {
    scrollToSection(refs[ref], ref);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className="NavbarContainer">
      <a href="#hero" className="Logo" onClick={() => scrollToSection(refs.homeRef, 'homeRef')}>
        <h1>ABDERRAHIM</h1>
      </a>
      <nav className="Nav">
        <ul className="NavList">
          {menuItems.map((item) => (
            <li key={item.ref}>
              <a
                href={`#${item.ref}`}
                className={activeSection === item.ref ? 'active' : ''}
                onClick={() => {
                  scrollToSection(refs[item.ref], item.ref);
                }}
              >
                {item.name}
              </a>
            </li>
          ))}
        </ul>
        <button className="dropdown-toggle" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
          ☰
        </button>
      </nav>

      {isDropdownOpen && (
        <div className={`dropdown-menu ${isDropdownOpen ? 'open' : ''}`}>
          <button className="close-button" onClick={() => setIsDropdownOpen(false)}>✖</button>
          <ul>
            {menuItems.map((item) => (
              <li key={item.ref}>
                <a
                  href={`#${item.ref}`}
                  onClick={() => handleMenuClick(item.ref)}
                >
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </header>
  );
}

export default Navbar;