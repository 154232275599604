import React from 'react';
import '../styles/Contact.css';

function Contact() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const name = event.target.elements[0].value;
    const email = event.target.elements[1].value;
    const subject = event.target.elements[2].value;
    const message = event.target.elements[3].value;

    // Construct the Gmail link
    const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&to=info@abderrahimboutorh.com&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(`Name: ${name}\nEmail: ${email}\nMessage: ${message}`)}`;
    
    // Open Gmail in a new window
    window.open(gmailLink, '_blank');
  };

  return (
    <section id="contact" className="contact">
      <div className="contact-header">
        <h2>Contact Me</h2>
        <p><strong><em>"A conversation can open doors to new opportunities." — Anonymous</em></strong></p>
      </div>
      <div className="contact-form-container">
        <div className="contact-image">
          <img src={`${process.env.PUBLIC_URL}/rahimboutorh-no_background.png`} alt="Contact" />
        </div>
        <form className="contact-form" onSubmit={handleSubmit}>
          <input type="text" placeholder="Your Name" required />
          <input type="email" placeholder="Your Email" required />
          <input type="text" placeholder="Subject" required />
          <textarea placeholder="Message" required></textarea>
          <button type="submit" className="button button-primary">Send Message</button>
        </form>
      </div>
      {/* <div className="contact-info">
        <div className="info-item">
          <img src={`${process.env.PUBLIC_URL}/linkedin-logo.png`} alt="LinkedIn Logo" className="social-logo" />
        </div>
        <div className="info-item">
          <img src={`${process.env.PUBLIC_URL}/twitter-logo.png`} alt="Twitter Logo" className="social-logo" />
        </div>
        <div className="info-item">
          <img src={`${process.env.PUBLIC_URL}/instagram-logo.png`} alt="Instagram Logo" className="social-logo" />
        </div>
        <div className="info-item">
          <div className="icon">✉️</div>
          <h4>info@abderrahimboutorh.com</h4>
        </div>
      </div> */}
    </section>
  );
}

export default Contact;