import React from 'react';
import '../styles/Blog.css';

// Function to capitalize the first letter of each word
const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, char => char.toUpperCase());
}

// Blog data extracted from the component logic
const blogPosts = [
    {
        id: 1,
        title: capitalizeWords("Which Scalability Pattern to Choose? Revealing Advanced Strategies in Software Engineering"),
        date: "Dec 19, 2023",
        description: "This tutorial explores key scalability patterns like Load Balancing, Pipes and Filters, and Execution Orchestrator. It provides strategies for optimizing performance and scalability in modern systems.",
        image: `${process.env.PUBLIC_URL}/scalability.webp`,
        link: "https://medium.com/@boutorh.abderrahim/which-scalability-pattern-to-choose-revealing-advanced-strategies-in-software-engineering-93f9172ab2c5"
    },
    {
        id: 2,
        title: capitalizeWords("Explore your data lake using Amazon Athena for Apache Spark"),
        date: "Dec 7, 2023",
        description: "This tutorial guides you through setting up Apache Spark on Amazon Athena to explore data in S3 data lakes. It covers creating workgroups, configuring IAM, and running Spark queries for real-time analytics.",
        image: `${process.env.PUBLIC_URL}/spark1.jpeg`,
        link: "https://www.linkedin.com/pulse/explore-your-data-lake-using-amazon-athena-apache-spark-boutorh-mkqqc/?trackingId=2SZfgLCsQtSz3HA0JCFqyQ%3D%3D"
    },
    {
        id: 3,
        title: capitalizeWords("Use generative AI with Amazon EMR, Amazon Bedrock, and English SDK for Apache Spark to unlock insights"),
        date: "Dec 11, 2023",
        description: "This tutorial explains how to enhance data analytics using generative AI with Amazon EMR, Bedrock, and the pyspark-ai library. It simplifies Apache Spark usage by converting English instructions into PySpark objects.",
        image: `${process.env.PUBLIC_URL}/genai.png`,
        link: "https://www.linkedin.com/pulse/use-generative-ai-amazon-emr-bedrock-english-sdk-apache-boutorh-dmbce/?trackingId=2SZfgLCsQtSz3HA0JCFqyQ%3D%3D"
    }
];

function Blog() {
    return (
        <section id="blog" className="blog">
            <div className="blog-header">
                <h2>My Blog</h2>
                <p>"Writing is the painting of the voice." — Voltaire</p>
            </div>

            <div className="blog-grid">
                {blogPosts.map(post => (
                    <div className="blog-item" key={post.id} onClick={() => window.open(post.link, '_blank')}>
                        <a href={post.link} target="_blank" rel="noopener noreferrer">
                            <img src={post.image} alt={`Blog ${post.id}`} />
                            <div className="blog-content">
                                <p className="blog-meta">{post.date}<span>3</span></p>
                                <h3>{post.title}</h3>
                                <p>{post.description}</p>
                            </div>
                        </a>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default Blog;
