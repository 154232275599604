import React from 'react';
import '../styles/Hero.css';

function Hero({ scrollToSection, refs }) {
    return (
        <section id="home" className="hero">
            <div className="hero-text">
                <p className="intro">Hey!</p>
                <h1>I'm <span>Abderrahim Boutorh</span></h1>
                <p className="profession">I Help You Deploy Cloud Applications, Migrate Workloads, And Save Costs on AWS.</p>
                <div className="buttons">
                    <button className="button button-primary" onClick={() => scrollToSection(refs.contactRef)}>
                        Let's Chat!
                    </button>
                    <button 
                        className="button button-secondary" 
                        onClick={() => scrollToSection(refs.blogRef)}
                    >
                        Read My Blog
                    </button>
                </div>
            </div>
            <div className="hero-image">
                <div className="aws-logo-container">
                    <img src={`${process.env.PUBLIC_URL}/aws5.png`} alt="AWS Logo" className="aws-logo" />
                </div>
            </div>
        </section>
    );
}

export default Hero;