import React, { useEffect, useState } from 'react';
import '../styles/About.css';

// Data structure for about text
const aboutData = [
    {
        title: "About Me",
        content: [
            "Hey, I'm Abderrahim — a Senior Platform Engineer with a passion for tackling complex problems and creating impactful solutions.",
            "My fascination with technology began at the age of five when I first explored the wonders of my first personal computer. That early curiosity set me on a path to understanding how technology works and how it can be used to improve lives.",
            "Beyond my work in designing robust software and systems, I'm an avid reader, an enthusiastic traveller, and someone who enjoys helping others utilise technology to solve real-world challenges.",
            "This website is a platform for me to share my journey, insights, and experiences while connecting with professionals and innovators who share similar interests. As one of my favourite quotes inspires me:",
            "“Never doubt that a small group of thoughtful, committed citizens can change the world; indeed, it's the only thing that ever has.” — Margaret Mead",
        ]
    }
];

function About() {
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        const aboutSection = document.getElementById('about');
        const sectionTop = aboutSection.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (sectionTop < windowHeight - 100) {
            setIsVisible(true);
            window.removeEventListener('scroll', handleScroll);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <section id="about" className={`about ${isVisible ? 'fade-in' : ''}`}>
            <div className="about-content">
                <div className="about-text">
                    <h2>{aboutData[0].title}</h2>
                    {aboutData[0].content.map((paragraph, index) => (
                        index === aboutData[0].content.length - 1 ? (
                            <p key={index} style={{ fontWeight: 'bold', fontStyle: 'italic' }}>{paragraph}</p>
                        ) : (
                            <p key={index}>{paragraph}</p>
                        )
                    ))}
                </div>
                <div className="about-image">
                    <img src={`${process.env.PUBLIC_URL}/rahimboutorh-no_background.png`} alt="Abderrahim Boutorh" />
                </div>
            </div>
        </section>
    );
}

export default About;