import React, { useEffect, useState } from 'react';
import '../styles/Resume.css';

function Resume() {
    const [visibleItems, setVisibleItems] = useState([]);

    const handleScroll = () => {
        const items = document.querySelectorAll('.resume-item');
        items.forEach((item, index) => {
            const itemTop = item.getBoundingClientRect().top;
            const windowHeight = window.innerHeight;

            if (itemTop < windowHeight - 100) {
                setVisibleItems((prev) => {
                    if (!prev.includes(index)) {
                        return [...prev, index]; // Add index to visible items
                    }
                    return prev;
                });
            }
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <section id="resume" className="resume">
            <div className="resume-header">
                <h2>Resume</h2>
                <p>"Success is a journey, not a destination. The doing is often more important than the outcome." — Arthur Ashe</p>
            </div>
            <div className="resume-timeline">
                {resumeData.map((item, index) => (
                    <div
                        key={index}
                        className={`resume-item ${visibleItems.includes(index) ? 'fade-in' : ''}`}
                    >
                        <img src={item.logo} alt={`${item.company} Logo`} className="company-logo" />
                        <div className="resume-content">
                            <h3>{item.date}</h3>
                            <h4>{item.position} - {item.company}</h4>
                            <ul>
                                {item.description.map((desc, i) => (
                                    <li key={i}>{desc}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}

const resumeData = [
    {
        date: '2024 - Present',
        position: 'Sr AWS Cloud Platform Engineer',
        company: 'RWE Supply & Trading',
        logo: `${process.env.PUBLIC_URL}/rwe.jpg`,
        description: [
            'Designing, implementing, and maintaining scalable infrastructure.',
            'Streamlining development workflows by creating and maintaining CI/CD pipelines and automation processes.',
            'Ensuring platform security, compliance, and cost optimisation.',
        ],
    },
    {
        date: '2023 - 2024',
        position: 'Software Engineer - Data Platform',
        company: 'Wayve',
        logo: `${process.env.PUBLIC_URL}/wayve.jpg`,
        description: [
            'Optimised data pipelines for efficient data ingestion, cleansing, and processing.',
            'Developed internal tools for the data platform team.',
            'Technologies: Azure, Terraform, Kafka, Kubernetes, SQL, Python, Databricks.',
        ],
            },
    {
        date: '2021 - 2023',
        position: 'Software Engineering Squad Lead',
        company: 'Betterspace',
        logo: `${process.env.PUBLIC_URL}/betterspace.png`,
        description: [
            'Led a team of 4 engineers to build the back-end of the platform.',
            'Provided technical guidance and designed key systems.',
            'Developed an internal payment platform with Stripe integration.',
        ],
    },
    {
        date: '2019 - 2021',
        position: 'Software Engineer - AWS Cloud Infrastructure',
        company: 'Betterspace',
        logo: `${process.env.PUBLIC_URL}/betterspace.png`,
        description: [
            'Designed a microservices architecture with AWS, Node.js, and serverless tech.',
            'Implemented infrastructure using AWS CloudFormation, Serverless Framework, and AWS SAM.',
            'Set up CI/CD pipelines with AWS CodePipeline, GitHub, AWS CodeBuild, and CircleCI.',
        ],
    },
    {
        date: '2017 - 2018',
        position: 'MSc in Internet of Things (Data)',
        company: 'Queen Mary University of London',
        logo: `${process.env.PUBLIC_URL}/queenmary.jpg`,
        description: [
            'Grade: Distinction',
            'Relevant coursework: Machine Learning, Data Analytics, Cloud Computing, IoT',
            'Member of the Students\' Union',
        ],
    },
    {
        date: '2014 - 2017',
        position: 'Engineer\'s Degree in Electrical and Electronics Engineering',
        company: 'Ecole Nationale Polytechnique',
        logo: `${process.env.PUBLIC_URL}/enp.png`,
        description: [
            'Grade: Distinction (85%)',
            'Thesis: "Implementation of RAKE receiver for W-CDMA on FPGA"',
            'Active member of Club d\'Activités Polyvalents',
        ],
    },
];

export default Resume;
