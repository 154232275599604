import React from 'react';
import '../styles/Footer.css';

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-about">
                    <h3>About</h3>
                    <p>I Help You Deploy Cloud Applications And Migrate Your Workloads To The AWS Cloud.</p>
                </div>
                <div className="footer-links">
                    <h3>Links</h3>
                    <ul>
                        <li><a href="#home">Home</a></li>
                        <li><a href="#about">About</a></li>
                        <li><a href="#resume">Resume</a></li>
                        <li><a href="#blog">My Blog</a></li>
                        <li><a href="#contact">Contact</a></li>
                    </ul>
                </div>
                <div className="footer-contact">
                    <h3>Have a Questions?</h3>
                    <p>📍 London, UK</p>
                    <p>✉️ info@abderrahimboutorh.com</p>
                    <div className="footer-social-icons">
                        <a href="https://x.com/a_boutorh" target="_blank" rel="noopener noreferrer">
                            <img src={`${process.env.PUBLIC_URL}/twitter-logo.png`} alt="Twitter" className="social-logo" />
                        </a>
                        <a href="https://www.linkedin.com/in/abderrahim-boutorh/" target="_blank" rel="noopener noreferrer">
                            <img src={`${process.env.PUBLIC_URL}/linkedin-logo.png`} alt="LinkedIn" className="social-logo" />
                        </a>
                        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                            <img src={`${process.env.PUBLIC_URL}/instagram-logo.png`} alt="Instagram" className="social-logo" />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
