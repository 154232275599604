// src/App.js

import React, { useRef, useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import About from './components/About';
import Resume from './components/Resume';
// import Services from './components/Services';
import Blog from './components/Blog';
import Contact from './components/Contact';
import Footer from './components/Footer';
import './styles/global.css';

function App() {
  // Create references for each section
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const resumeRef = useRef(null);
  const servicesRef = useRef(null);
  const blogRef = useRef(null);
  const contactRef = useRef(null);

  const [activeSection, setActiveSection] = useState('home');

  // Smooth scroll function
  const scrollToSection = (ref, section) => {
    if (ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop,
        behavior: 'smooth'
      });
      setActiveSection(section);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const sections = [
        { ref: homeRef, name: 'home' },
        { ref: aboutRef, name: 'about' },
        { ref: resumeRef, name: 'resume' },
        { ref: servicesRef, name: 'services' },
        { ref: blogRef, name: 'blog' },
        { ref: contactRef, name: 'contact' }
      ];

      const scrollPosition = window.scrollY + window.innerHeight / 2;

      for (const section of sections) {
        if (
          section.ref.current &&
          section.ref.current.offsetTop <= scrollPosition &&
          section.ref.current.offsetTop + section.ref.current.offsetHeight > scrollPosition
        ) {
          setActiveSection(section.name);
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="app">
      <Navbar scrollToSection={scrollToSection} refs={{ homeRef, aboutRef, resumeRef, servicesRef, blogRef, contactRef }} activeSection={activeSection} />
      <section ref={homeRef}><Hero scrollToSection={scrollToSection} refs={{ blogRef, contactRef, aboutRef }} /></section>
      <section ref={aboutRef}><About refs={{ blogRef, contactRef, aboutRef }} /></section>
      <section ref={resumeRef}><Resume /></section>
      {/* <section ref={servicesRef}><Services /></section> */}
      <section ref={blogRef}><Blog /></section>
      <section ref={contactRef}><Contact /></section>
      <Footer />
    </div>
  );
}

export default App;